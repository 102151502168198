import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "CLINICS アプリのリニューアルの裏側 (iOS 編)",
  "date": "2022-02-15T09:00:02.000Z",
  "slug": "entry/2022/02/15/180002",
  "tags": ["medley"],
  "hero": "./2022_02_15.png",
  "heroAlt": "CLINICS アプリのリニューアルの裏側 (iOS 編)"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`こんにちは、医療プラットフォーム本部/プロダクト開発室/第一開発グループ所属の世嘉良です。
メドレーには 2018 年の頭に入社しており、今年で 4 年目になります。 当初はサーバーサイドを中心に開発を担当していたのですが、最近は患者エンゲージメントチームという患者様に提供するサービスを開発するチームで主に iOS の仕事を担当することが多いです。`}</p>
    <p>{`さて去年の 12 月になりますが、 `}<a parentName="p" {...{
        "href": "https://clinics-app.com/"
      }}>{`CLINICS アプリ`}</a>{` は `}<a parentName="p" {...{
        "href": "https://www.medley.jp/release/20211207.html"
      }}>{`UI のフルリニューアル`}</a>{`を行いました。
今回はリニューアルの裏話 (iOS について) をしていきたいと思います。`}</p>
    <h1>{`これまでの CLINICS アプリについて`}</h1>
    <p>{`本題を書く前に、CLINICS アプリの歴史を紹介します。
ファーストコミットを見てみると、アプリの開発は 2016 年 2 月ごろからスタートし、ファーストリリースが行われたのが 2016 年 5 月でした。`}</p>
    <p>{`当初、担当していたエンジニアは iOS の経験が豊富な方はおらず、全員で試行錯誤しながら開発を進めていたようです。
しばらくの間は機能の追加などが行われていましたが、`}<a parentName="p" {...{
        "href": "https://clinics-cloud.com/karte"
      }}>{`CLINICS カルテ`}</a>{` の開発に注力するために大きな開発はストップし、`}<a parentName="p" {...{
        "href": "https://pharms-cloud.com"
      }}>{`Pharms`}</a>{` との連携が開始される 2020 年 5 月頃まで機能や設計に関する見直しがほとんど行われてきませんでした。`}</p>
    <p>{`iOS に詳しいエンジニアがいなかったにも関わらず、かなりのスピード感でリリースしていることはさすがの開発力という一言に尽きるのですが、Pharms との連携やお薬手帳といった機能が追加されたり、今後の開発を見据えた際に既存機能や設計の見直しを行いたいと思うようになってきました。`}</p>
    <p>{`改善したい部分はたくさんあったのですが、対応工数を踏まえ今回のリニューアルでは以下の 2 点の改善に注力することにしました。`}</p>
    <ol>
      <li parentName="ol">{`Storyboard による View の管理`}</li>
      <li parentName="ol">{`View とロジックの分離`}</li>
    </ol>
    <p>{`この 2 点についてそれぞれ詳しく説明します。`}</p>
    <h1>{`1. Storyboard による View の管理`}</h1>
    <p>{`従来の開発では Storyboard を利用して View を作成していました。
Storyboard を使うとレイアウトや画面遷移を簡単に実装することができますが、開発を続けているうちに以下のような問題が目立つようになってきました。`}</p>
    <ul>
      <li parentName="ul">{`Storyboard が巨大化していき、複数人開発を行う際に支障がでる`}</li>
      <li parentName="ul">{`レイアウトに追加・変更が行われた場合に AutoLayout の再設定に時間がかかる`}</li>
      <li parentName="ul">{`コンポーネント自体にサイズが設定されていることがあり、コンポーネントを再利用できないことがある`}</li>
    </ul>
    <h2>{`課題`}</h2>
    <p>{`こちらは実際にあった Storyboard のキャプチャですが、複数の画面が 1 つの Storyboard 内に詰め込まれた状態となっていました。
Storyboard は Xcode のバージョンにより微妙な差分が発生してしまったり、AutoLayout の調整が必要になる場合があります。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20220210/20220210195921.png",
        "alt": "f:id:medley_inc:20220210195921p:plain"
      }}></img></p>
    <p>{`こちらは古い Storyboard の画面を開いた後の差分なのですが、この変更がシステムによって加えられた変更なのか、他人の改修による変更なのかを後から見た時にわかりづらいという問題がありました。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20220210/20220210200019.png",
        "alt": "f:id:medley_inc:20220210200019p:plain"
      }}></img></p>
    <p>{`仮に問題が発生した場合は修正を試みるのですが、独自の XML によって表現されているため、iOS の経験が浅い僕にとっては修正が非常に難しかったです。`}</p>
    <p>{`また、複数人で並行して開発する際にもコンフリクトが起きやすくなってしまい解消に時間がかかるという問題となっていました。`}</p>
    <p>{`さらに従来の CLINICS アプリは `}<a parentName="p" {...{
        "href": "https://speakerdeck.com/medley/clinics-dls-fan-shi-che-di-tesainyan-yu-sisutemufalsekoshao-jie"
      }}>{`DLS`}</a>{` を利用してレイアウトを作成していたのですが、コンポーネントに直接サイズが設定されているものがあり、ある画面では微妙にサイズを調整したい…といった要件に対応できず、せっかくのコンポーネントを再利用できないケースがありました。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20220210/20220210200108.png",
        "alt": "f:id:medley_inc:20220210200108p:plain"
      }}></img></p>
    <h2>{`解決案`}</h2>
    <p>{`課題に対する解決案は色々と考えられますが、弊社の開発スタイルやエンジニアのスキルを考慮し以下のような方針を立てました。`}</p>
    <ul>
      <li parentName="ul">{`Storyboard と各画面の実装は 1 : 1 の関係とする`}</li>
      <li parentName="ul">{`画面遷移の責務も Storyboard から切り離す`}</li>
      <li parentName="ul">{`コンポーネントにアトミックデザインを適用する`}</li>
    </ul>
    <p>{`Storyboard の分割は以下のようなステップで行っていました。`}</p>
    <ol>
      <li parentName="ol">{`Refactor to Storyboard を使って巨大な Storyboard を分割する`}</li>
      <li parentName="ol">{`SwiftGen を利用し画面生成のコードを自動作成する`}</li>
      <li parentName="ol">{`2 で生成したものを利用して画面遷移を行う`}</li>
      <li parentName="ol">{`既存の Segue を削除する`}</li>
    </ol>
    <p>{`まず最初に Xcode 7 から利用可能となった「Refactor to Storyboard」を使って画面を分割するところから始めます。`}</p>
    <p>{`この機能を利用すると選択した View が新しい Storyboard に切り出され、元の Storyboard には切り出した Storyboard へのリファレンスや Segue 等の接続が保持された状態になります。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20220210/20220210200138.png",
        "alt": "f:id:medley_inc:20220210200138p:plain"
      }}></img></p>
    <p>{`次に各画面間の遷移を Segue を使わずに行うようにします。
Segue は便利なのですが、Identifier が単なる文字列であったり、Storyboard を分割してもリファレンスは保持しておく必要がある点が微妙に感じてしまい利用しないことにしました。`}</p>
    <p>{`Segue を使わずに画面遷移を行う必要があるため、画面生成と画面遷移の方法を自前で実装する必要があります。
今回は画面生成の処理を `}<a parentName="p" {...{
        "href": "https://github.com/SwiftGen/SwiftGen"
      }}>{`SwiftGen`}</a>{` を利用して自動生成可能にし、VIPER というアーキテクチャの Router を参考にして画面遷移を Storyboard から切り離すことにしました。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20220210/20220210200200.png",
        "alt": "f:id:medley_inc:20220210200200p:plain"
      }}></img></p>
    <p>{`※ SwiftGen の利用方法は `}<a parentName="p" {...{
        "href": "https://github.com/SwiftGen/SwiftGen#interface-builder"
      }}>{`SwiftGen#interface-builder`}</a>{` を参照ください。`}</p>
    <p>{`Router の実装は以下の通りです。
実装されたプロトコルを遷移元の VC に継承し、画面遷移のコードを呼び出すだけで画面遷移を行うことができます。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "swift"
    }}><pre parentName="div" {...{
        "className": "language-swift"
      }}><code parentName="pre" {...{
          "className": "language-swift"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`protocol`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`ClinicWireframe`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`AnyObject`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` viewController`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`UIViewController`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`get`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`func`}</span>{` `}<span parentName="code" {...{
            "className": "token function-definition function"
          }}>{`presentClinic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`clinicId`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`String`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`func`}</span>{` `}<span parentName="code" {...{
            "className": "token function-definition function"
          }}>{`pushClinic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`clinicId`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`String`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`extension`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`ClinicWireframe`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`func`}</span>{` `}<span parentName="code" {...{
            "className": "token function-definition function"
          }}>{`presentClinic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`clinicId`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`String`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`let`}</span>{` vc `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`StoryboardScene`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`Clinic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`initialScene`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`instantiate `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
            `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`ClinicViewController`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`coder`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token short-argument"
          }}>{`$0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` isHeaderEnabled`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`let`}</span>{` presenter `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`ClinicPresenter`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`view`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` vc`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
        presenter`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`setClinicId`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`clinicId`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` clinicId`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
        vc`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`inject`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`presenter`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` presenter`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
        vc`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`modalPresentationStyle `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`pageSheet
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`let`}</span>{` nc `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`UINavigationController`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`rootViewController`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` vc`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
        viewController`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`present`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`nc`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` animated`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`func`}</span>{` `}<span parentName="code" {...{
            "className": "token function-definition function"
          }}>{`pushClinic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`clinicId`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`String`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`let`}</span>{` vc `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`StoryboardScene`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`Clinic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`initialScene`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`instantiate `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
            `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`ClinicViewController`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`coder`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token short-argument"
          }}>{`$0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` isHeaderEnabled`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`let`}</span>{` presenter `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`ClinicPresenter`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`view`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` vc`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
        presenter`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`setClinicId`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`clinicId`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` clinicId`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
        vc`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`inject`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`presenter`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` presenter`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
        viewController`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`navigationController`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`pushViewController`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`vc`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` animated`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`最後にコンポーネントの調整についてですが、CLINICS アプリではアトミックデザインを簡略化し、以下のような基準でコンポーネントを実装しています。`}</p>
    <ul>
      <li parentName="ul">{`Block: UI の最小単位 (他の PJT にも持ち込めそうなレベルまで分解されたもの)`}</li>
      <li parentName="ul">{`Partial: CLINICS の PJT 固有のコンポーネント`}</li>
      <li parentName="ul">{`Layout: ヘルプ用のモーダルやエラー画面など他の画面から呼び出されることで初めて意味をなす画面など`}</li>
      <li parentName="ul">{`Page: 各 ViewController とそれに紐づく Storyboard`}</li>
    </ul>
    <p>{`この管理方法自体は 弊社の別プロダクトの開発を担当しているエンジニアによって考案されたものです。
弊社ではサーバー・フロント分け隔てなく開発を任されることも多く、厳密なアトミックデザインだとコンポーネントの分類に困ることが多かったためこのような管理方法をとっているそうです。
今回のリニューアルに際して CLINICS アプリでもこれを参考にすることにしました。`}</p>
    <p>{`これまで DLS として管理されていたコンポーネントは Block で実装しなおし、Width / Height といったサイズの設定を行わないようにしました。`}</p>
    <p>{`このようにコンポーネントの実装レベルを明確にしたことで、実装に一定の指針が生まれ使い回しの効くコンポーネントを作成しやすくなりました。`}</p>
    <h1>{`2. View とロジックの分離`}</h1>
    <p>{`スピード開発が求められた背景を考えると仕方のないことではあるのですが、従来の CLINICS アプリでは ViewController にロジックが記述されており、俗にいう Fat ViewController の状態になってしまっていました。`}</p>
    <p>{`Fat ViewController の問題点については既にさまざまな方が取り上げていますが、以下の部分が問題と感じています。`}</p>
    <ul>
      <li parentName="ul">{`UI とロジックが分離されていないため、テストを書くことが難しい`}</li>
      <li parentName="ul">{`可読性が低くなりがち`}</li>
      <li parentName="ul">{`ロジックが切り出されていないため似たような実装が点在する場合がある`}</li>
    </ul>
    <h2>{`課題`}</h2>
    <p>{`こちらは実際にあった ViewController の一部です。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20220210/20220210200226.png",
        "alt": "f:id:medley_inc:20220210200226p:plain"
      }}></img></p>
    <p>{`このコードに関しては以下の部分が問題と感じていました。`}</p>
    <ul>
      <li parentName="ul">{`通信処理の呼び出しが ViewController の責務になっていること`}</li>
      <li parentName="ul">{`通信結果を整形するロジックが ViewController の責務になっていること`}</li>
      <li parentName="ul">{`画面描画のための State 管理が ViewController の責務になっていること`}</li>
    </ul>
    <h2>{`解決案`}</h2>
    <p>{`UI とロジックを分離するための手法はさまざまなものがありますが、弊社のアプリには以下のような特徴があります。`}</p>
    <ul>
      <li parentName="ul">{`iOS 以外にも複数のプラットフォームをサポートしているためフロントエンドで保持するデータや複雑なロジック自体は少ない (サーバー側でなるべく担保している)`}</li>
      <li parentName="ul">{`実装時や QA 時に感じた違和感について細かくディレクターと打ち合わせし、その結果次第では仕様を変更することがある`}</li>
    </ul>
    <p>{`これらを考慮すると、プロジェクトの期間的に初期の導入コストが低く、データフローがシンプルなものに留めたいというように考えがまとまってきました。`}</p>
    <p>{`これらを考慮し、CLINICS アプリでは `}<strong parentName="p">{`MVP`}</strong>{` というアーキテクチャを採用することにしました。
より詳細には MVP の Passive View 方式を採用しており、以下のような形で実装しています。`}</p>
    <ul>
      <li parentName="ul">{`View は基本的にすべての入力イベントに対応した Presenter の処理を呼び出す`}</li>
      <li parentName="ul">{`Presenter は入力に応じて通信処理などの外部要因となる処理を呼び出し、結果を整形する`}</li>
      <li parentName="ul">{`プレゼンテーションロジックの結果を描画するように View に指示を出す`}</li>
      <li parentName="ul">{`View は Presenter の指示によってのみ描画処理を行い、自身を起点とした描画処理は行わない`}</li>
    </ul>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20220210/20220210201010.png",
        "alt": "f:id:medley_inc:20220210201010p:plain",
        "title": "[Model–view–presenter - Wikipedia](https://en.wikipedia.org/wiki/Model%E2%80%93view%E2%80%93presenter)"
      }}></img></p>
    <p><a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Model%E2%80%93view%E2%80%93presenter"
      }}>{`Model–view–presenter - Wikipedia`}</a></p>
    <p>{`既に Router は導入してるため、複雑な処理フローを実装したい場合は Interactor を導入するだけで VIPER アーキテクチャへと発展させることが簡単にできる点も魅力でした。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20220210/20220210200319.png",
        "alt": "f:id:medley_inc:20220210200319p:plain"
      }}></img></p>
    <p>{`CLINICS アプリでの ViewController / Presenter の実装を簡単にまとめたものは以下のようになっています。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "swift"
    }}><pre parentName="div" {...{
        "className": "language-swift"
      }}><code parentName="pre" {...{
          "className": "language-swift"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`final`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`ClinicViewController`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`UIViewController`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`private`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`lazy`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` presenter`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`ClinicPresenterInput`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token function"
          }}>{`fatalError`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`“`}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Failed`}</span>{` to inject presenter”`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`override`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`func`}</span>{` `}<span parentName="code" {...{
            "className": "token function-definition function"
          }}>{`viewDidLoad`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`super`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`viewDidLoad`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
        presenter`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`refresh`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`func`}</span>{` `}<span parentName="code" {...{
            "className": "token function-definition function"
          }}>{`inject`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`presenter`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`ClinicPresenterInput`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`self`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`presenter `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` presenter
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// タップされた際に呼び出す`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`func`}</span>{` `}<span parentName="code" {...{
            "className": "token function-definition function"
          }}>{`onTapServiceCell`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token omit keyword"
          }}>{`_`}</span>{` service`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`ServiceEntity`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` isTelemedicine`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Bool`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        presenter`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`didTapServiceButton`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`service`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` isTelemedicine`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` isTelemedicine`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// MARK: - ClinicPresenterOutput`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`extension`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`ClinicViewController`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`ClinicPresenterOutput`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`func`}</span>{` `}<span parentName="code" {...{
            "className": "token function-definition function"
          }}>{`reloadData`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`clinic`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`ClinicEntity`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        clinicViewStore`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`clinic `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` clinic
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`func`}</span>{` `}<span parentName="code" {...{
            "className": "token function-definition function"
          }}>{`openCreateAppointmentView`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`clinic`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`ClinicEntity`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` service`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`ServiceEntity`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` isTelemedicine`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Bool`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
        `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// 予約へ進む`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`func`}</span>{` `}<span parentName="code" {...{
            "className": "token function-definition function"
          }}>{`showErrorAlert`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token omit keyword"
          }}>{`_`}</span>{` error`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Error`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// エラー表示を行う`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "swift"
    }}><pre parentName="div" {...{
        "className": "language-swift"
      }}><code parentName="pre" {...{
          "className": "language-swift"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`protocol`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`ClinicPresenterInput`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`AnyObject`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`func`}</span>{` `}<span parentName="code" {...{
            "className": "token function-definition function"
          }}>{`refresh`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`func`}</span>{` `}<span parentName="code" {...{
            "className": "token function-definition function"
          }}>{`didTapServiceButton`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token omit keyword"
          }}>{`_`}</span>{` service`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`ServiceEntity`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` isTelemedicine`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Bool`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`protocol`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`ClinicPresenterOutput`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`AnyObject`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`func`}</span>{` `}<span parentName="code" {...{
            "className": "token function-definition function"
          }}>{`reloadData`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`clinic`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`ClinicEntity`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`func`}</span>{` `}<span parentName="code" {...{
            "className": "token function-definition function"
          }}>{`openCreateAppointmentView`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`clinic`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`ClinicEntity`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` service`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`ServiceEntity`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` isTelemedicine`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Bool`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`func`}</span>{` `}<span parentName="code" {...{
            "className": "token function-definition function"
          }}>{`showErrorAlert`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token omit keyword"
          }}>{`_`}</span>{` error`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Error`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`final`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`class`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`ClinicPresenter`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`private`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`let`}</span>{` clinicRepository`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`ClinicRepository`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`private`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` clinic`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`ClinicEntity`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`private`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` cancellables`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Set`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`AnyCancellable`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`init`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`init`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`view`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`ClinicPresenterOutput`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` container`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`DIContainer`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`self`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`view `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` view
        clinicRepository `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` container`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`resolve`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`private`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`func`}</span>{` `}<span parentName="code" {...{
            "className": "token function-definition function"
          }}>{`reloadView`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        view`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`reloadData`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`clinic`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` clinic`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// MARK: - PresenterInput`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`extension`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`ClinicPresenter`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`ClinicPresenterInput`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`func`}</span>{` `}<span parentName="code" {...{
            "className": "token function-definition function"
          }}>{`refresh`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`guard`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`let`}</span>{` clinicId `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` clinicId `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`else`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
        clinicRepository`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getClinic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`clinicId`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` clinicId`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
        	`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`sink`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`
            receiveCompletion`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`weak`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`self`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` completion `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`in`}</span>{`
                    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`guard`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`let`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`self`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`self`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`else`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
                    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`guard`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`case`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`let`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`failure`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`error`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` completion `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`else`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
                    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`self`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`view`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`showErrorAlert`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`error`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
                `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
            `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
            receiveValue`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`[`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`weak`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`self`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`]`}</span>{` response `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`in`}</span>{`
                `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`guard`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`let`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`self`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`self`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`else`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
                `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`self`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`clinic `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` response`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`data
                `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`self`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`reloadView`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
            `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`store`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token keyword"
          }}>{`in`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`&`}</span>{`cancellables`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`func`}</span>{` `}<span parentName="code" {...{
            "className": "token function-definition function"
          }}>{`didTapServiceButton`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token omit keyword"
          }}>{`_`}</span>{` service`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`ClinicsServiceEntity`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` isTelemedicine`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Bool`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`guard`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`let`}</span>{` clinic `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` clinic `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`else`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`return`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
        view`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`openCreateAppointmentView`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`clinic`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` clinic`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` service`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` service`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` isTelemedicine`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` isTelemedicine`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`ViewController と Presenter は以下のように Router の内部で DI するようにしています。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "swift"
    }}><pre parentName="div" {...{
        "className": "language-swift"
      }}><code parentName="pre" {...{
          "className": "language-swift"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`protocol`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`ClinicWireframe`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`AnyObject`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`var`}</span>{` viewController`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`UIViewController`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`get`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`func`}</span>{` `}<span parentName="code" {...{
            "className": "token function-definition function"
          }}>{`pushClinic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`clinicId`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`String`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`extension`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`ClinicWireframe`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`func`}</span>{` `}<span parentName="code" {...{
            "className": "token function-definition function"
          }}>{`pushClinic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`clinicId`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`String`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`let`}</span>{` vc `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`StoryboardScene`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`Clinic`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`initialScene`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`instantiate `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
            `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`ClinicViewController`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`coder`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token short-argument"
          }}>{`$0`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` isHeaderEnabled`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`false`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`let`}</span>{` presenter `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`ClinicPresenter`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`view`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` vc`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` container`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`DIContainer`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
        presenter`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`setClinicId`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`clinicId`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` clinicId`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
        vc`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`inject`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`presenter`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` presenter`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
       viewController`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`navigationController`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`?`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`pushViewController`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`vc`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` animated`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token boolean"
          }}>{`true`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`これによって、もともと ViewController にあった処理は以下のように分離されました。`}</p>
    <p><img parentName="p" {...{
        "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20220210/20220210200341.png",
        "alt": "f:id:medley_inc:20220210200341p:plain"
      }}></img></p>
    <p>{`また、View と Presenter はインターフェースを介してしかお互いを知らないため、実装の交換が簡単にできるようになりました。
Presenter を交換することで 1 つの View で別々の処理を表現することが可能となったり、View をテストコードを交換することで Presenter の入出力値をテストすることができるようになっています。`}</p>
    <h1>{`まとめ`}</h1>
    <p>{`CLINICS アプリは歴史のあるプロジェクトですが、今回のプロジェクトを通して UI だけでなく裏側の実装も刷新しています。
巨大な Storyboard を分解したことでメンテナビリティが向上し、MVP (+ Router) の導入によって View とロジックの交換が簡単になり、テストなどの実装を取り入れやすくなりました。`}</p>
    <h1>{`さいごに`}</h1>
    <p>{`ブログの本編には書けなかったのですが、今回リニューアルされた画面に関しては SwiftUI を利用してフルスクラッチで実装していたり、 Asset の管理方法についても大きな見直しを行いました。`}</p>
    <p>{`またアーキテクチャの選定にあたり、「`}<a parentName="p" {...{
        "href": "https://peaks.cc/books/iOS_architecture"
      }}>{`iOS アプリ設計パターン入門`}</a>{`」が大変参考になりました。iOS の開発を行う方はぜひ一読してほしいと思います。`}</p>
    <p>{`約半年ほどかかった大きなプロジェクトでしたが、患者エンゲージメントチームのメンバー全員で取り組み無事にリリースまで漕ぎ着けることができました。まだまだ手探りな部分もありますが、今後も患者さんにとってより安心して使えるサービスとなるように開発を続けていければと思っています。`}</p>
    <p>{`長くなりましたが、最後までお読みいただきありがとうございました。`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.medley.jp/jobs/"
      }}>{`https://www.medley.jp/jobs/`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      